export const environment = {
  env: 'staging',
  frameRouterOrigin: [
    'https://app.stage.ngin-staging.com',
    'https://org.ui.stage.ngin-staging.com',
    'https://org.stage.ngin-staging.com',
    'https://org.ui.sportngin.com.dev',
    'https://org.sportngin.com.dev'
  ],
  production: false,
  staging: true,
  urls: {
    api: ['https://se-api.sestage.us', 'https://api.stage.ngin-staging.com'],
    siteRedirect: 'https://admin.stage.ngin-staging.com/site_admin/org_redirect',
    eligibilityListUi: 'https://eligibility-list.ui.stage.ngin-staging.com'
  }
}
