import { Routes } from '@angular/router'
import { NotFoundComponent } from './not-found.component'
import { SeFeFrameRouterService } from 'se-fe-frame-router'

export const routes: Routes = [
  {
    path: 'credential_search',
    data: {
      frameRouterTitle: 'Credentials',
      pageDepth: 'HQ.CredentialSearch'
    },
    canActivate: [SeFeFrameRouterService],
    children: [
      {
        path: '',
        loadChildren: () => import('./credential-search/credential-search.module').then(m => m.CredentialSearchModule)
      },
      { path: '**', component: NotFoundComponent, data: { pageDepth: 'NotFound' } },
    ]
  },
  {
    path: '',
    data: {
      frameRouterTitle: 'Eligibility Search',
      pageDepth: 'HQ.EligibilitySearch'
    },
    canActivate: [SeFeFrameRouterService],
    children: [
      {
        path: '',
        loadChildren: () => import('./eligibility-search/eligibility-search.module').then(m => m.EligibilitySearchModule)
      },
      { path: '**', component: NotFoundComponent, data: { pageDepth: 'NotFound' } },
    ]
  }
]
